import * as React from 'react'

import styled from 'styled-components'
import { mq, spacing } from '@thg-commerce/gravity-theme'
import {
  SimpleCta,
  SimpleTitleWidget,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  SimpleTitleAndCta,
  SimpleTitleAndCtaProps,
} from '@thg-commerce/enterprise-widget-simple-title-and-cta'
import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

export type SimpleTitleAndCtaRendererProps = BaseWidgetProps &
  (Omit<SimpleCta, 'query' | 'simpleCTABackgroundColour'> &
    Omit<SimpleTitleWidget, 'query'>) & {
    backgroundColour: string
  }

const StyledSimpleTitleAndCta = styled(SimpleTitleAndCta)`
  padding: 0 ${spacing(2)};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: 0 ${spacing(5)};
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    padding: 0 ${spacing(3)};
  }
`
export const SimpleTitleAndCtaRenderer = (
  props: SimpleTitleAndCtaRendererProps,
) => {
  if (props.__typename === WidgetTypes.simpleCTA) {
    if (!props.CTAUrl || !props.CTAText) {
      return null
    }
    const simpleTitleAndCtaProps: SimpleTitleAndCtaProps = {
      subtitle: props.title || undefined,
      buttonProps: {
        CTAUrl: props.CTAUrl,
        CTAText: props.CTAText,
        CTAStyle: props.CTAStyle,
      },
      secondButtonProps: {
        secondCTAText: props.secondCTAText,
        secondCTAUrl: props.secondCTAUrl,
        secondCTAStyle: props.secondCTAStyle,
      },
      backgroundColour: props.backgroundColour,
    }
    return <StyledSimpleTitleAndCta {...simpleTitleAndCtaProps} />
  }
  if (props.__typename === WidgetTypes.simpleTitleWidget) {
    if (props.title || props.subtitle || props.text) {
      const simpleTitleAndCtaProps: SimpleTitleAndCtaProps = {
        title: props.title || undefined,
        subtitle: props.subtitle || undefined,
        textAlign: (props.textAlign as HorizontalAlignment) || 'center',
        text: props.text || undefined,
      }
      return <StyledSimpleTitleAndCta {...simpleTitleAndCtaProps} />
    }
    return null
  }
  return null
}
