import { styled, Text, spacing, margin } from '@thg-commerce/enterprise-theme'
import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { Button } from '@thg-commerce/gravity-elements'

export const Title = styled.h1<{
  alignment: HorizontalAlignment
}>`
  ${Text('xlarge1', 'alternate')}
  color: ${(props) => props.theme.colors.palette.greys.darker};
  text-align: ${(props) => props.alignment};
  ${(props) => margin(props.theme.widget.simpleTitleAndCta.title.margin)}};
`

export const ButtonsContainer = styled.div<{
  alignment: HorizontalAlignment
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.alignment};
  margin: auto;
  margin-top: ${spacing(1)};
`
export const StyledButton = styled(Button)`
  margin-top: ${spacing(2)};
`

export const SimpleTitleAndCtaText = styled.p<{
  alignment: HorizontalAlignment
}>`
  ${Text('bodyText', 'default')};
  margin-top: ${spacing(2)};
  text-align: ${(props) => props.alignment};
`

export const Subtitle = styled.h2<{
  alignment: HorizontalAlignment
}>`
  ${Text('large1', 'alternate')};
  margin-top: ${spacing(2)};
  text-align: ${(props) => props.alignment};
`
