import * as React from 'react'
import { Property } from 'csstype'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { WidgetBackgroundColour } from '@thg-commerce/gravity-elements'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  ButtonsContainer,
  SimpleTitleAndCtaText,
  StyledButton,
  Subtitle,
  Title,
} from './styles'

export interface SimpleTitleAndCtaProps {
  className?: string
  title?: string
  subtitle?: string
  text?: string
  textAlign?: HorizontalAlignment
  buttonProps?: {
    CTAUrl: string
    CTAText: string
    CTAStyle: string
  }
  secondButtonProps?: {
    secondCTAText: string
    secondCTAUrl: string
    secondCTAStyle: string
  }
  backgroundColour?: Property.Color
}

enum EMPHASIS_SELECTOR {
  primary = 'high',
  secondary = 'medium',
  tertiary = 'low',
}

const PrefetchStyledButton = withPrefetch('href', StyledButton)

export const SimpleTitleAndCta = (props: SimpleTitleAndCtaProps) => {
  const renderSecondButton =
    !!props.secondButtonProps?.secondCTAText &&
    !!props.secondButtonProps?.secondCTAUrl &&
    !!props.secondButtonProps?.secondCTAStyle
  return (
    <WidgetBackgroundColour backgroundColour={props.backgroundColour}>
      <div className={props.className}>
        {props.title && (
          <Title
            alignment={props.textAlign || 'center'}
            data-testid="widget-title"
          >
            {props.title}
          </Title>
        )}

        {props.subtitle && (
          <Subtitle
            alignment={props.textAlign || 'center'}
            data-testid="widget-subtitle"
          >
            {props.subtitle}
          </Subtitle>
        )}

        {props.text && (
          <SimpleTitleAndCtaText
            alignment={props.textAlign || 'center'}
            data-testid="widget-text"
          >
            {props.text}
          </SimpleTitleAndCtaText>
        )}
        {props.buttonProps && (
          <ButtonsContainer alignment={props.textAlign || 'center'}>
            <PrefetchStyledButton
              renderedAs="a"
              href={props.buttonProps.CTAUrl}
              data-testid="widget-button"
              emphasis={
                EMPHASIS_SELECTOR[props.buttonProps.CTAStyle || 'medium']
              }
            >
              {props.buttonProps.CTAText}
            </PrefetchStyledButton>
            {renderSecondButton && (
              <PrefetchStyledButton
                renderedAs="a"
                href={props.secondButtonProps?.secondCTAUrl}
                data-testid="widget-button"
                emphasis={
                  EMPHASIS_SELECTOR[
                    props.secondButtonProps?.secondCTAStyle || 'medium'
                  ]
                }
              >
                {props.secondButtonProps?.secondCTAText}
              </PrefetchStyledButton>
            )}
          </ButtonsContainer>
        )}
      </div>
    </WidgetBackgroundColour>
  )
}
